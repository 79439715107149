import styles from './welcome.module.scss';

import BigLogo  from './../../assets/logo-big.png';
import { ReactComponent as ScrollDown }  from './../../assets/scroll-down.svg';


const Welcome = () => {
    return (
    <div className={styles.Wrapper}>
        <div className={styles.BigLogo}>
            <img src={BigLogo} alt="DominionDAO" fill="none" />
        </div>

        <div className={styles.Title}>
            Welcome to Dominion DAO
        </div>

        <div className={styles.Info}>
            <span>empowering a new breed of decentralised organisations entrepid entrpreneurs and enterprises</span>
        </div>

        <div className={styles.Button}>
            <button><a href="https://dominiondao.typeform.com/registration" target="_blank" rel="noreferrer noopener" >Create Guild</a> </button>
        </div>

        <div className={styles.ScrollText}>
            Scroll down to explore
        </div>

        <ScrollDown className={styles.ScrollDownIcon}/>
    </div>)
}

export default Welcome;