import styles from './tokenomics.module.scss';

import DominionDaoLogo  from './../../assets/logo-65.png';

import { ReactComponent as TitleLogo }  from './../../assets/logo-title.svg';
import { ReactComponent as RightArrow }  from './../../assets/little-arrow-right.svg';


const Tokenomics = () => {


    const isSmallResolution = window.innerWidth < 768;

    return (
    <div id="tokenomics" className={styles.Wrapper}>
        
        <div className={[styles.Left, styles.MarginTop288].join(' ')}>
            <div className={styles.Row}>
                <div>
                    <img src={DominionDaoLogo} alt="Dominion DAO" fill="none" />
                </div>

                <TitleLogo />
            </div>

            <div className={[styles.Title].join(' ')}>
                Tokenomics
            </div>

            <div className={[styles.Text, styles.MarginTop20].join(' ')}>
                At the time we are seeking to raise between <span>$150,000</span> and <span>$450,000</span> USD from sale of the <span>$RIFT</span> governance token across Private and Public rounds.
            </div>

            <div className={[styles.Text, styles.MarginTop30].join(' ')}>
                Token holders will be able to participate in network governance and the management of our projects, enjoy early access to features and applications, share in revenue generated by all projects, claim rewards and gifts, utilise staking pools and other DeFi services and other such utility as we grow and build the <span>Dominon DAO ecosystem.</span>
            </div>

        </div>

        <div className={styles.Right}>

            <div className={styles.Box}>
                <div className={styles.InnerBox}>
                    <div className={[styles.BoxText, styles.MarginTop30].join(' ')}>$RIFT Total Token Supply</div>
                    <div className={[styles.BoxNumber, styles.MarginTop20].join(' ')} >50,000,000</div>
                </div>
            </div>

            <div className={[styles.Row, styles.MarginTop50].join(' ')}>
                <div className={[styles.Col].join(' ')}>
                    <div>Team</div>
                    <div><span>30%</span></div>
                </div>
                
                <div className={[styles.Col].join(' ')}>
                    <div>Marketing</div>
                    <div><span>10%</span></div>
                </div>

                <div className={[styles.Col].join(' ')}>
                    <div>Advisors</div>
                    <div><span>10%</span></div>
                </div>

                {!isSmallResolution ? <div className={[styles.Col].join(' ')}>
                    <div>Private Round</div>
                    <div><span>10%</span></div>
                </div> : null}
                
                
            </div>


            <div className={[styles.Row, styles.MarginTop30].join(' ')}>
                {isSmallResolution ? <div className={[styles.Col].join(' ')}>
                    <div>Private Round</div>
                    <div><span>10%</span></div>
                </div> : null}

                <div className={[styles.Col].join(' ')}>
                    <div>Public Round</div>
                    <div><span>10%</span></div>
                </div>
                
                <div className={[styles.Col].join(' ')}>
                    <div>Ecosystem</div>
                    <div><span>30%</span></div>
                </div>
            </div>

            <div className={styles.Button}>
                <a href="https://buy-rift.ethrift.io/" target="_blank" rel="noreferrer noopener" >Link to Token Sale</a>
                <RightArrow />
            </div>

        </div>

    </div>)
}

export default Tokenomics;