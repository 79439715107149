// import logo from './logo.svg';

import './App.css';

import Wrapper from './containers/wrapper/Wrapper';

function App() {
  return (
    <div className="App">
      <Wrapper />
    </div>
  );
}

export default App;
