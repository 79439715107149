import styles from './promotion.module.scss';

import LimeChainPartner  from './../../assets/limechain-partner.png';
import CreoPartner  from './../../assets/creo-partner.png';
import FerrumPartner  from './../../assets/ferrum-partner.png';
// import MonoovaPartner  from './../../assets/monoova-partner.png';
// import HyperledgerPartner  from './../../assets/hyperledger-partner.png';
// import RapidIdPartner  from './../../assets/rapidid-partner.png';
// import EthereumPartner  from './../../assets/ethereum-partner.png';

import TwitterSocial  from './../../assets/twitter-social.png';
import DiscordSocial  from './../../assets/discord-social.png';
import TelegramSocial  from './../../assets/telegram-social.png';
import RedditSocial  from './../../assets/reddit-social.png';
// import RaribleSocial  from './../../assets/rarible-social.png';
// import MediumSocial  from './../../assets/medium-social.png';
// import OpenSeaSocial  from './../../assets/opensea-social.png';

import DominionDaoNFTCollection  from './../../assets/dominion-dao-nft-collection.png';
import OmniCATNFTCollection  from './../../assets/omnicat-nft-collection-2.png';
import ReDefine  from './../../assets/redefine-2.png';

import { ReactComponent as RightArrow }  from './../../assets/little-arrow-right.svg';
import { onClickSocial } from '../../utils/utils';


const Promotion = () => {


    return (
    <div className={styles.Wrapper}>
        
        <div id="partners" className={styles.Partners}>
            
            <div className={[styles.PartnerRow, styles.MarginTop148].join(' ')}>
                <div className={styles.Title}>
                    PARTNERS
                </div>
            
            </div>
            
            {/* // TODO: on partner click */}
            <div className={styles.PartnerRow}>
                <div className={[styles.Partner, styles.LimeChainPartner].join(' ')} onClick={() => onClickSocial('https://limechain.tech')}>
                    <img src={LimeChainPartner} alt="Dominion DAO LimeChain" fill="none" />
                </div>

                <div className={[styles.Partner, styles.CreoPartner].join(' ')} onClick={() => onClickSocial('https://creolegal.com.au')}>
                    <img src={CreoPartner} alt="Dominion DAO Creo" fill="none" />
                </div>

                <div className={[styles.Partner, styles.FerrumPartner].join(' ')} onClick={() => onClickSocial('https://ferrum.network')}>
                    <img src={FerrumPartner} alt="Dominion DAO Ferrum" fill="none" />
                </div>

                {/* <div className={styles.Partner}>
                    <img src={MonoovaPartner} alt="Dominion DAO Monoova" fill="none" />
                </div> */}
            </div>

            {/* <div className={styles.PartnerRow}>

                <div className={styles.Partner}>
                    <img src={HyperledgerPartner} alt="Dominion DAO Hyperledger" fill="none" />
                </div>

                <div className={styles.Partner}>
                    <img src={RapidIdPartner} alt="Dominion DAO RapidId" fill="none" />
                </div>

                <div className={styles.Partner}>
                    <img src={EthereumPartner} alt="Dominion DAO Ethereum" fill="none" />
                </div>

            </div> */}

        </div>
        
        <div id="promotions" className={styles.Promotions}>
            <div className={styles.PromotionTitle}>Arcane Arts Launch Promotions</div>

            <div className={styles.PromotionRow}>
            
                <div className={styles.PromoBox}>
                    <div><img src={DominionDaoNFTCollection} alt="Dominion DAO NFT" fill="none" /></div>
                    <div className={[styles.PromoBoxText, styles.MarginTop20].join(' ')}>Guild themed art tokens</div>
                    <div className={[styles.PromoBoxTitle, styles.MarginTop10].join(' ')}>DominionDAO NFT Collection</div>
                    <div className={[styles.PromoBoxText, styles.MarginTop20].join(' ')}>Blockchain Adventurer Collectibles</div>
                    <div className={[styles.PromoBoxLink, styles.MarginTop25].join(' ')}>
                        <a href="https://www.arcanearts.io/#block-a7d01618657a71f61c93" target="_blank" rel="noreferrer noopener" >To Collection</a>
                        <RightArrow />
                    </div>
                </div>
                
                <div  className={styles.PromoBox}>
                    <div><img src={OmniCATNFTCollection} alt="Dominion DAO NFT" fill="none" /></div>
                    <div className={[styles.PromoBoxText, styles.MarginTop20].join(' ')}>Biochemistry themed art tokens</div>
                    <div className={[styles.PromoBoxTitle, styles.MarginTop10].join(' ')}>omniCAT NFT Collection</div>
                    <div className={[styles.PromoBoxText, styles.MarginTop10].join(' ')}>Play to Learn and Earn; Health and Medicine Story Based Education</div>
                    <div className={[styles.PromoBoxLink, styles.MarginTop16].join(' ')}>
                        <a href="https://www.arcanearts.io/#block-4a5722e1f603aa737d6d" target="_blank" rel="noreferrer noopener" >To Collection</a>
                        <RightArrow />
                    </div>
                </div>
                
                <div  className={styles.PromoBox}>
                    <div><img src={ReDefine} alt="Dominion DAO NFT" fill="none" /></div>
                    <div className={[styles.PromoBoxText, styles.MarginTop20].join(' ')}>Blockchain Photography</div>
                    <div className={[styles.PromoBoxTitle, styles.MarginTop10].join(' ')}>ReDefine</div>
                    <div className={[styles.PromoBoxText, styles.MarginTop20].join(' ')}>Redefining revenue streams between Artists, Models and Patrons</div>
                    <div className={[styles.PromoBoxLink].join(' ')}>
                        <a href="https://www.arcanearts.io/#block-8ed1221f37aadb19a18b" target="_blank" rel="noreferrer noopener" >To Collection</a>
                        <RightArrow />
                    </div>
                </div>
            
            </div>

            <div className={styles.AfterPromotion}>
                <div className={styles.TitleAfterPromotion}>
                    And many more to come
                </div>

                <div className={styles.TextAfterPromotion}>
                    Join us now at our public channels
                </div>

            </div>

            
            <div className={styles.SocialRow}>
                <div onClick={() => onClickSocial('https://twitter.com/DominionDAO')}><img src={TwitterSocial} alt="Dominion DAO Twitter" fill="none" className={styles.Clickable} /></div>
                <div onClick={() => onClickSocial('https://discord.gg/hwFsyyg8qH')}><img src={DiscordSocial} alt="Dominion DAO Discord" fill="none" className={styles.Clickable} /></div>
                <div onClick={() => onClickSocial('https://t.me/dominiondao')}><img src={TelegramSocial} alt="Dominion DAO Telegram" fill="none" className={styles.Clickable} /></div>
                {/* <div onClick={() => onClickSocial('https://rarible.com/arcanearts')}><img src={RaribleSocial} alt="Dominion DAO Rarible" fill="none" className={styles.Clickable} /></div> */}
                <div onClick={() => onClickSocial('https://www.reddit.com/r/DominionDAO')}><img src={RedditSocial} alt="Dominion DAO Reddit" fill="none" className={styles.Clickable} /></div>
                {/* <div onClick={() => onClickSocial('https://dominiondao.medium.com')}><img src={MediumSocial} alt="Dominion DAO Medium" fill="none" className={styles.Clickable} /></div> */}
                {/* <div onClick={() => onClickSocial('https://opensea.io/Arcane_Arts')}><img src={OpenSeaSocial} alt="Dominion DAO OpenSea" fill="none" className={styles.Clickable} /></div> */}
            </div>


        </div>


    </div>)
}

export default Promotion;