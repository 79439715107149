import styles from './wrapper.module.scss';

import Header from '../../components/header/Header';
import Welcome from '../../components/welcome/Welcome';
import Mission from '../../components/mission/Mission';
import NewWorld from '../../components/new-world/NewWorld';
import OwnData from '../../components/data/OwnData';
import MasterplanMarkets from '../../components/masterplan-markets/MasterplanMarkets';
import Pipeline from '../../components/pipeline/Pipeline';
import Tokenomics from '../../components/tokenomics/Tokenomics';
import Company from '../../components/company/Company';
import Promotion from '../../components/promotions/Promotion';
import Team from '../../components/team/Team';
import Footer from '../../components/footer/Footer';
import { useState } from 'react';

import { ReactComponent as XIcon }  from './../../assets/x-icon.svg';
import { useWindowDimension } from '../../hooks/window-dimenssion-hook';

const initState = {
    isMenuClicked: false,
    isWidthBelowNeeded: false
}

const Wrapper = () => {

    const [ isMenuClicked, setIsMenuClicked ] = useState(initState.isMenuClicked);
    const [width] = useWindowDimension();

    // https://stackoverflow.com/questions/18546067/why-is-the-window-width-smaller-than-the-viewport-width-set-in-media-queries
    const isSmallResolution = width < 1440 + 15;

    const onClickMenu = () => {
        setIsMenuClicked(!isMenuClicked);
    }


    const scrollTo = (id) => {
        document.getElementById(id).scrollIntoView();

        if (isSmallResolution) {
            onClickMenu();
        }
    }

    return (<div id="top" className={styles.Wrapper} >

        { isMenuClicked ? 
            <div className={[styles.MenuContent, styles.RepositionMenu].join(' ')}>
                {isSmallResolution && isMenuClicked ? <div className={styles.XIcon} onClick={onClickMenu}><XIcon /></div> : null}
                <div onClick={() => scrollTo('top')}>Top</div>
                <div onClick={() => scrollTo('mission')}>Mission</div>
                <div onClick={() => scrollTo('new-world')}>New World</div>
                <div onClick={() => scrollTo('own-our-data')}>Own your data</div>
                <div onClick={() => scrollTo('masterplan')}>Masterplan</div>
                <div onClick={() => scrollTo('markets')}>Target markets</div>
                <div onClick={() => scrollTo('pipeline')}>Current pipeline</div>
                <div onClick={() => scrollTo('tokenomics')}>Tokenomics</div>
                <div onClick={() => scrollTo('company')}>Company</div>
                <div onClick={() => scrollTo('partners')}>Partners</div>
                <div onClick={() => scrollTo('promotions')}>Promotions</div>
                <div onClick={() => scrollTo('team')}>Team</div>
            </div> 
        : null }

        <Header isMenuClicked={isMenuClicked} onClickMenu={onClickMenu}/>
        <Welcome />
        <Mission />
        <NewWorld />
        <OwnData />
        <MasterplanMarkets />
        <Pipeline />
        <Tokenomics />
        <Company />
        <Promotion />
        <Team />
        <Footer />
    </div>)
}

export default Wrapper;