import styles from './team.module.scss';

import LinkedInSocial  from './../../assets/linkedin-social.png';
import HariMuralidharan  from './../../assets/hari-muralidharan.png';
import DavidChung  from './../../assets/david-chung.png';
import AndrewBish  from './../../assets/andrew-bish.png';
import LimeChain  from './../../assets/limechain-logo.png';
import { onClickSocial } from '../../utils/utils';

const Team = () => {


    return (
    <div id="team" className={styles.Wrapper}>

        
        <div className={styles.Title}>Team</div>
        
        <div className={styles.MembersRow}>

            <div className={styles.Box}>
                
                <div className={styles.BoxContent}>
                    <div><img src={HariMuralidharan} alt="Dominion DAO Hari K. Muralidharan" fill="none" /></div>
                    <div className={styles.Line}></div>
                    <div className={styles.Name}>Hari K. Muralidharan</div>
                    <div className={styles.Description}>Future focused designer and innovator specializing in digital healthcare</div>
                </div>
                
                <div className={styles.BoxContent}>
                    <div className={styles.Address}>Gold Coast, Australia</div>
                    <div onClick={() => onClickSocial('https://www.linkedin.com/in/haribo')}><img src={LinkedInSocial} alt="Dominion DAO Twitter" fill="none" className={styles.Clickable} /></div>
                </div>

            </div>

            <div className={styles.Box}>
                
                <div className={styles.BoxContent}>
                    <div><img src={DavidChung} alt="Dominion DAO David A. Chung" fill="none" /></div>
                    <div className={styles.Line}></div>
                    <div className={styles.Name}>David A. Chung</div>
                    <div className={styles.Description}>Multi-disciplinary lawyer supporting growing businesses and Blockchain startups</div>
                </div>
                
                <div className={styles.BoxContent}>
                    <div className={styles.Address}>Brisbane, Australia</div>
                    <div onClick={() => onClickSocial('https://www.linkedin.com/in/davidachung')}><img src={LinkedInSocial} alt="Dominion DAO Twitter" fill="none" className={styles.Clickable} /></div>
                </div>

            </div>

            <div className={styles.Box}>
                
                <div className={styles.BoxContent}>
                    <div><img src={AndrewBish} alt="Dominion DAO Andrew Bish" fill="none" /></div>
                    <div className={styles.Line}></div>
                    <div className={styles.Name}>Andrew Bish</div>
                    <div className={styles.Description}>Skilled business operator excelling in legal and financial professional networking focused in the Southeast Asia region</div>
                </div>
                
                <div className={styles.BoxContent}>
                    <div className={styles.Address}>Bangkok, Thailand</div>
                    {/* <div onClick={() => onClickSocial('google.com')}><img src={LinkedInSocial} alt="Dominion DAO Twitter" fill="none" className={styles.Clickable} /></div> */}
                </div>

            </div>

            <div className={styles.Box}>
                
                <div className={styles.BoxContent}>
                    <div><img src={LimeChain} alt="Dominion DAO LimeChain" fill="none" /></div>
                    <div className={styles.Line}></div>
                    <div className={styles.Name}>LimeChain</div>
                    <div className={styles.Description}>Professional blockchain and DLT services for enterprises and entrepreneurs</div>
                </div>
                
                <div className={styles.BoxContent}>
                    <div className={styles.Address}>Sofia, Bulgaria</div>
                    <div onClick={() => onClickSocial('https://www.linkedin.com/company/limechain')}><img src={LinkedInSocial} alt="Dominion DAO Twitter" fill="none" className={styles.Clickable} /></div>
                </div>

            </div>

        </div>


    </div>)
}

export default Team;