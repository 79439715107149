import styles from './masterplan-markets.module.scss';

import HandWithStars  from './../../assets/hand-with-stars.png';
import HandPlanets  from './../../assets/hand-with-planets.png';
import FullColorLogo  from './../../assets/full-color-logo.png';

import { ReactComponent as MiniTitle }  from './../../assets/mini-title.svg';
import { ReactComponent as CheckedIcon }  from './../../assets/checked-icon.svg';

const MasterplanMarkets = () => {
    return (
    <div className={styles.Wrapper}>

        <div className={styles.Up}>

            <div id="masterplan" className={styles.Left}>
                <MiniTitle  className={[styles.MarginTop224, styles.MiniTitle].join(' ')}/>

                <div className={styles.Title}>
                    The Masterplan
                </div>

                <div className={styles.Text}>
                    Our strategy is to undertake a series of projects each designed to develop specific competencies and generate resources such that, cumulatively, this effort will sufficiently prepare and evolve the Company to be able to deploy services in the Pacific Island region with all the necessary systems, knowledge, expertise and funding in place to execute our mission.
                </div>

                <div className={styles.CheckListItem}>
                    <CheckedIcon />
                    <div>
                        Series of projects develop key competencies
                    </div>
                </div>

                <div className={styles.CheckListItem}>
                    <CheckedIcon />
                    <div>
                    Establish a prescence in the Pacific Island region
                    </div>
                </div>

                <div className={styles.CheckListItem}>
                    <CheckedIcon />
                    <div>
                    Optimise trade and money flow between island nations
                    </div>
                </div>

                <div className={styles.CheckListItem}>
                    <CheckedIcon />
                    <div>
                    Promote efficient business by introducing Guilds, Syndicates and DAOs
                    </div>
                </div>

                <div className={styles.HandPlanetsImgWrapper}>
                    <img src={HandPlanets} alt="DominionDAO Marketplan" fill="none" />
                </div>

            </div>

            <div className={styles.Right}>
                
                <div className={styles.HandWithStarsImgWrapper}>
                    <img src={HandWithStars} alt="Dominion DAO Target Markets" fill="none" />
                </div>

                <div id="markets"></div>

                <MiniTitle  className={[styles.MarginTop276, styles.MiniTitleMarkets].join(' ')}/>

                <div className={styles.Title}>
                    Target Markets
                </div>

                <div className={styles.Text}>
                    <span>Our decentralised products will be accessible internationally however our target markets include our home countries Australia, New Zealand and Thailand, as well as, Fiji and other Pacific Island nations including Samoa, Tonga, Vanuatu, New Caledonia, the Solomon Islands and others comprising Melanesia, Micronesia and Polynesia.</span>
                    <br></br>
                    <br></br>
                    <span className={styles.MarginTop10}>The populace of these countries can benefit significantly from optimised money flows, blockchain enhanced business mechanics and open access healthcare systems.</span>
                </div>
                
            </div>

        </div>

        <div className={[styles.Down, styles.FullColorLogo].join(' ')}>

            <img src={FullColorLogo} alt="Dominion DAO" fill="none" />
        </div>

        

    </div>)
}

export default MasterplanMarkets;