import styles from './company.module.scss';

import HugeLogo  from './../../assets/huge-color-logo.png';
import HugeLogo2  from './../../assets/huge2-logo.png';

import { ReactComponent as TitleLogo }  from './../../assets/logo-title.svg';


const Company = () => {
    return (
    <div id="company" className={styles.Wrapper}>
        
        <div className={styles.Left}>
        
        <div className={styles.BackgroundImage}>
                <img src={HugeLogo2} alt="Dominion DAO" fill="none" />
            </div>

            <div className={styles.Image}>
                <img src={HugeLogo} alt="Dominion DAO" fill="none" />
            </div>

            

        </div>
        
        <div className={styles.Right}>
            <TitleLogo className={styles.MarginTop90}/>

            <div className={styles.Title}>Our Company</div>

            <div className={[styles.Text, styles.MarginTop32].join(' ')}>
                <span>Sui Generis Pty Ltd</span> is an Australian Medical Innovation and Research Development firm based in Gold Coast, Queensland, Australia and established in November 2011. 
            </div>

            <div className={[styles.Text, styles.MarginTop20].join(' ')}>
                We specialise in <span>designing systems to support the provision of healthcare</span> and <span>delivering primary healthcare</span> with a focus on early detection and preventative measures. 
            </div>
            
            <div className={[styles.Text, styles.MarginTop20].join(' ')}>
                We have a <span>multi-disciplinary team</span> with experience, specialised knowledge and unique skills necessary to turn this vision into a reality.
            </div>
            
            <div className={[styles.Text, styles.MarginTop20].join(' ')}>
                Our intention is to <span>leverage</span> distributed ledger technology to <span>bridge</span> open financial systems and healthcare and <span>unlock</span> the future of a new generation.
            </div>
            
            <div className={[styles.Text, styles.MarginTop20].join(' ')}>
                We will found Dominion DAO’s first guild <span>Oceanic Blockchain Federation</span> to pursue our goal.
            </div>
            


            <div className={styles.TextGold}>Аll are welcome to join us in this venture.</div>
        </div>
    </div>)
}

export default Company;