import styles from './footer.module.scss';

// import TwitterSocial  from './../../assets/twitter-social.png';
// import DiscordSocial  from './../../assets/discord-social.png';
// import TelegramSocial  from './../../assets/telegram-social.png';
// import RaribleSocial  from './../../assets/rarible-social.png';
// import RedditSocial  from './../../assets/reddit-social.png';
// import MediumSocial  from './../../assets/medium-social.png';
// import OpenSeaSocial  from './../../assets/opensea-social.png';

import TwitterSocial2  from './../../assets/twitter-social-2.png';
import DiscordSocial2  from './../../assets/discord-social-2.png';
import TelegramSocial2  from './../../assets/telegram-social-2.png';
import RaribleSocial2 from './../../assets/rarible-social-2.png';
import RedditSocial2  from './../../assets/reddit-social-2.png';
import MediumSocial2  from './../../assets/medium-social-2.png';
import OpenSeaSocial2  from './../../assets/opensea-social-2.png';

import { ReactComponent as TitleLogo }  from './../../assets/logo-title.svg';
import { onClickSocial } from '../../utils/utils';


const Footer = () => {


    return (
    <div className={styles.Wrapper}>
        
        <div className={styles.Up}>
            <div className={styles.UpContent}>
                
                <div className={styles.Title}>All are welcome to join us in this venture!</div>
                
                <div className={styles.Text}>Designing blockchain solutions at the intersection of decentralised finance and open access health care</div>
                
                <div className={styles.SocialRow}>
                    <div onClick={() => onClickSocial('https://twitter.com/DominionDAO')}><img src={TwitterSocial2} alt="Dominion DAO Twitter" fill="none" className={styles.Clickable} /></div>
                    <div onClick={() => onClickSocial('https://discord.gg/hwFsyyg8qH')}><img src={DiscordSocial2} alt="Dominion DAO Discord" fill="none" className={styles.Clickable} /></div>
                    <div onClick={() => onClickSocial('https://t.me/dominiondao')}><img src={TelegramSocial2} alt="Dominion DAO Telegram" fill="none" className={styles.Clickable} /></div>
                    <div onClick={() => onClickSocial('https://rarible.com/arcanearts')}><img src={RaribleSocial2} alt="Dominion DAO Rarible" fill="none" className={styles.Clickable} /></div>
                    <div onClick={() => onClickSocial('https://www.reddit.com/r/DominionDAO')}><img src={RedditSocial2} alt="Dominion DAO Reddit" fill="none" className={styles.Clickable} /></div>
                    <div onClick={() => onClickSocial('https://dominiondao.medium.com')}><img src={MediumSocial2} alt="Dominion DAO Medium" fill="none" className={styles.Clickable} /></div>
                    <div onClick={() => onClickSocial('https://opensea.io/Arcane_Arts')}><img src={OpenSeaSocial2} alt="Dominion DAO OpenSea" fill="none" className={styles.Clickable} /></div>
                </div>

            </div>
        </div>
        
        <div className={styles.Down}>
            <TitleLogo className={styles.MarginTop95}/>

            <div className={styles.DownText}>Designing blockchain solutions at the intersection of decentralised finance and open access health care</div>

            {/* <div className={[styles.SocialRow, styles.MarginTop40].join(' ')}>
                <div onClick={() => onClickSocial('https://twitter.com/DominionDAO')}><img src={TwitterSocial} alt="Dominion DAO Twitter" fill="none" className={styles.Clickable} /></div>
                <div onClick={() => onClickSocial('https://discord.gg/hwFsyyg8qH')}><img src={DiscordSocial} alt="Dominion DAO Discord" fill="none" className={styles.Clickable} /></div>
                <div onClick={() => onClickSocial('https://t.me/dominiondao')}><img src={TelegramSocial} alt="Dominion DAO Telegram" fill="none" className={styles.Clickable} /></div>
                <div onClick={() => onClickSocial('https://rarible.com/arcanearts')}><img src={RaribleSocial} alt="Dominion DAO Rarible" fill="none" className={styles.Clickable} /></div>
                <div onClick={() => onClickSocial('https://www.reddit.com/r/DominionDAO')}><img src={RedditSocial} alt="Dominion DAO Reddit" fill="none" className={styles.Clickable} /></div>
                <div onClick={() => onClickSocial('https://dominiondao.medium.com')}><img src={MediumSocial} alt="Dominion DAO Medium" fill="none" className={styles.Clickable} /></div>
                <div onClick={() => onClickSocial('https://opensea.io/Arcane_Arts')}><img src={OpenSeaSocial} alt="Dominion DAO OpenSea" fill="none" className={styles.Clickable} /></div>
            </div> */}

        </div>

    </div>)
}

export default Footer;