import styles from './new-world.module.scss';

import NewWorldPNG  from './../../assets/new-world.png';
import { ReactComponent as MiniTitle }  from './../../assets/mini-title.svg';


const NewWorld = () => {
    return (
    <div id="new-world" className={styles.Wrapper}>

        <div className={styles.Left}>
            <div className={styles.MarginTop190}>
                <img className={styles.NewWorldPNG} src={NewWorldPNG} alt="DominionDAO Mission" fill="none" />
            </div>
        </div>
        
        <div className={styles.Right}>
            
            <MiniTitle className={[styles.MiniTitle, styles.MarginTop215].join(' ')} />
            
            <div className={styles.Title}>A New World</div>
            
            <div className={styles.Text}>
                <span>Blockchain is not just for digital money. Many familiar activities can be enhanced or reimagined entirely. It's possible to cheaply and quickly transfer funds internationally or, take a loan against your digital assets or, use NFT's as a way to digitise real world objects or tokenise artwork, represent ownership in them and secure royalties from sales in secondary markets.</span>
            </div>

        </div>
        
    </div>)
}

export default NewWorld;