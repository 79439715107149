import styles from './pipeline.module.scss';

import RiftLogo  from './../../assets/rift-logo.png';
import OmniCatLogo  from './../../assets//omnicat-logo.png';
import DominionDaoLogo  from './../../assets/logo-65.png';

import { ReactComponent as MiniTitle }  from './../../assets/mini-title.svg';
import { ReactComponent as RightArrow }  from './../../assets/little-arrow-right.svg';


const Pipeline = () => {
    return (
    <div id="pipeline" className={styles.Wrapper}>
        
        <div className={styles.Row}>
            
            <div className={[styles.InfoBox, styles.MarginTop180].join(' ')}>
                <MiniTitle />

                <div className={styles.Title}>
                    Our Current Pipeline
                </div>
                
                <div className={styles.Text}>
                    Our gamechanging lineup of products will revolutionise and revitalise the finance and healthcare industries with next generation technologies. 
                </div>

            </div>
            
            <div className={[styles.ProjectBox, styles.MarginLeft80, styles.MarginTop99].join(' ')}>
                
                <div className={styles.TitleRow}>
                    <div >
                        <img src={RiftLogo} alt="DominionDAO RIFT" fill="none" />
                    </div>
                    <div className={styles.TitleBox}>Blockchain Onboarding and Migration</div>
                </div>

                <div className={[styles.TextRow, styles.MarginTop25].join(' ')}>
                    <a href="https://ethrift.io" target="_blank" rel="noreferrer noopener" >ethrift.io</a> <span>FIAT-Crypto bridge licensed non-custodial digital currency exchange</span>
                </div>

                <div className={[styles.TextRow, styles.MarginTop30].join(' ')}>
                    Status
                    <span>Live Beta (Australia)</span>
                </div>

                <div className={[styles.TextRowLink, styles.MarginTop5].join(' ')}>
                    <div><a href="https://ethrift.io" target="_blank" rel="noreferrer noopener" >Go to ethrift.io</a></div>
                    <RightArrow />
                </div>

            </div>

        </div>
        
        <div className={[styles.Row, styles.MartinBottom78].join(' ')}>

            <div className={[styles.ProjectBox, styles.MarginRight60].join(' ')}>
                <div className={styles.TitleRow}>
                    <div >
                        <img src={DominionDaoLogo} alt="Dominion DAO" fill="none" />
                    </div>
                    <div className={styles.TitleBox}>De-Fi Enterprise Syndicate Trading</div>
                </div>

                <div className={[styles.TextRow, styles.MarginTop25].join(' ')}>
                <a href="https://DominionDAO.io" target="_blank" rel="noreferrer noopener" >DominionDAO.io</a> <span>Guild system for enterprise syndicate trading</span>
                </div>

                <div className={[styles.TextRow, styles.MarginTop30].join(' ')}>
                    Status
                    <span>In Development</span>
                </div>

                <div className={[styles.TextRowLink, styles.MarginTop5].join(' ')}>
                    <div><a href="https://docs.dominiondao.io/pitch/dominion-pitch" target="_blank" rel="noreferrer noopener" >Download pitch deck</a></div>
                    <RightArrow />
                </div>
            </div>

            <div className={[styles.ProjectBox].join(' ')}>

                <div className={styles.TitleRow}>
                    <div >
                        <img src={OmniCatLogo} alt="Dominion DAO omniCAT" fill="none" />
                    </div>
                    <div className={styles.TitleBox}>Decentralised Universal Healthcare Framework</div>
                </div>

                <div className={[styles.TextRow, styles.MarginTop25].join(' ')}>
                    <a href="https://omniCAT.org" target="_blank" rel="noreferrer noopener" >omniCAT.org</a> <span>  Personal and professional biometrics screening, assessment and optimisation</span>
                </div>

                <div className={[styles.TextRow, styles.MarginTop5].join(' ')}>
                    Status
                    <span>In Development</span>
                </div>

                <div className={[styles.TextRowLink, styles.MarginTop5].join(' ')}>
                    <div><a href="https://docs.dominiondao.io/pitch/ethrift-or-omnicat" target="_blank" rel="noreferrer noopener" >Download pitch deck</a></div>
                    <RightArrow />
                </div>

            </div>
        </div>

    </div>)
}

export default Pipeline;