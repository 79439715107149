import styles from './header.module.scss';

import Logo  from './../../assets/huge-color-logo.png';

import GitBookIcon  from './../../assets/gitbook.png';
import { ReactComponent as LogoTitle }  from './../../assets/logo-title.svg';

import { ReactComponent as OptionsIcon }  from './../../assets/options-icon.svg';
import { ReactComponent as XIcon }  from './../../assets/x-icon.svg';
// import { ReactComponent as LogoMain }  from './../../assets/logo-main.svg';

import { onClickSocial } from '../../utils/utils';
import { useWindowDimension } from '../../hooks/window-dimenssion-hook';

const Header = (props) => {

    const [width] = useWindowDimension();

    // https://stackoverflow.com/questions/18546067/why-is-the-window-width-smaller-than-the-viewport-width-set-in-media-queries
    let shoudHideElOnLowerResolution = width < 1440 + 15;
    let showLogoAsText = width >= 768;



    return (
    <div className={styles.Wrapper}>

        <div className={[styles.RowLeft].join(' ')}>
            <div className={ [shoudHideElOnLowerResolution ? styles.LogoPng : null, styles.LogoMain ].join(' ')} >
                <img src={Logo} alt="DominionDAO" fill="none" width="44" height="44"/>
                {/* <LogoMain /> */}
            </div>
            { !shoudHideElOnLowerResolution || showLogoAsText ? <LogoTitle className={styles.MarginLeft10}/> : null }
        </div>

        <div className={[styles.RowRight].join(' ')}>

            <div className={styles.GithubBox} onClick={() => onClickSocial('https://docs.dominiondao.io')}>
                <img src={GitBookIcon} alt="DominionDAO GitBook" fill="none" className={shoudHideElOnLowerResolution ? styles.GithubIcon : null}/>
                {/* <GitBookIcon className={shoudHideElOnLowerResolution ? styles.GithubIcon : null}/> */}
                { shoudHideElOnLowerResolution ? null : "To GitBook Docs" }
            </div>

            <div className={[styles.Menu, props.isMenuClicked ? styles.OpenMenu : null].join(' ')} onClick={props.onClickMenu}>
                { !props.isMenuClicked ? <OptionsIcon onClick={props.onClickMenu}/> : <XIcon /> }

                { shoudHideElOnLowerResolution ? null :  <div>Menu</div> }
            </div>

        </div>

    </div>)
}

export default Header;