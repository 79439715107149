import styles from './our-data.module.scss';

import VeryBigLogo  from './../../assets/very-big-logo.png';
import MagicHands  from './../../assets/magic-hands.png';

import { ReactComponent as MiniTitle }  from './../../assets/mini-title.svg';


const OwnData = () => {
    return (
    <div id="own-our-data" className={styles.Wrapper}>

        <div className={styles.Left}>

            <div className={styles.ImageWrapper}>
                <img src={MagicHands} alt="DominionDAO Own Data" fill="none" />
            </div>

            <MiniTitle className={styles.MarginTop144}/>

            <div className={styles.Title}>
                Own Your Own Data
            </div>

            <div className={styles.Text}>Traditionally, internet services harvest customer activity data in exchange for access. Blockchain systems are open by default and do not require anything more than a wallet to access. Anything from financial data to healthcare data can be securely stored and accessible only by your wallet. Be empowered by blockchain to have control of your finance and health data.</div>

        </div>

        <div className={styles.Right}>
            <img src={VeryBigLogo} alt="DominionDAO Own Data" fill="none" />
        </div>

    </div>)
}

export default OwnData;