export const onClickSocial = (url) => {
    let prefix = 'https://';

    if (!url.startsWith(prefix)) {
        url = prefix + url;
    }

    window.open(url, '_blank', 'noopener,noreferrer');
}

export const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView();
}