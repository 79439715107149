import styles from './mission.module.scss';

import Mission  from './../../assets/mission.png';
import { ReactComponent as MiniTitle }  from './../../assets/mini-title.svg';


const Welcome = () => {
    return (
    <div id="mission" className={styles.Wrapper}>
        
        <div className={styles.Left}>
            
            <MiniTitle className={styles.MiniTitle} />
            
            <div className={styles.Title}>The Mission</div>
            
            <div className={styles.Box}>
                <div className={styles.BoxTitle}>
                    Healthcare
                </div>
                <div className={styles.BoxText}>
                    <span>Our mission is to establish a multisite hospital and health service on the main island of Fiji supported by a roaming medical research and humanitarian aid vessel to deliver open finance and healthcare to the residents of the Pacific Island nations. </span>
                </div>
            </div>
            
            <div className={[styles.Box, styles.MarginTop25].join(' ')}>
                <div className={styles.BoxTitle}>
                    Potential for isolated nations
                </div>
                <div className={styles.BoxText}>
                    <span>Our application of blockchain technologies will forge networks of farmers, merchants, artisans and professionals bridge the geographic distance between ocean locked islands and unlock the potential of these isolated nations by introducing new and exciting next generation capabilities to the population.</span>
                </div>
            </div>

        </div>
        
        <div className={styles.Right}>
            <div>
                <img className={styles.MissionPng} src={Mission} alt="DominionDAO Mission" fill="none" />
            </div>
        </div>
    </div>)
}

export default Welcome;